export const authActions = {
  //Logout
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  //Login Actions
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_INVALID: "LOGIN_INVALID",
  RESET_LOGIN: "RESET_LOGIN",
  CLEAR_LOGIN_ERROR: "CLEAR_LOGIN_ERROR",
  FUNDS: "FUNDS",
  PROFILE_PIC: "PROFILE_PIC",
  IS_OUT_OF_OFFICE: "IS_OUT_OF_OFFICE",
  MENU_RIGHTS: "MENU_RIGHTS",
  LOGIN_MESSAGE: "LOGIN_MESSAGE"
};
