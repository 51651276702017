import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Logo from "../asset/images/logo.png";

const HideAddBtn = ["account"];

const Header = () => {
  const history = useHistory();
  const location = useLocation();

  const { token, info } = useSelector((state) => state.user);
  const { pathname } = location;

  const [HideBtn, setHideBtn] = useState(false);
  const [IsSeller, setIsSeller] = useState(false);

  useEffect(() => {
    if (!token) {
      RedirectPage("/");
    }
    if (info?.role_id === 3) {
      setIsSeller(true)
    } else {
      setIsSeller(false)
    }
  }, [token, info]);

  useEffect(() => {
    let Url = pathname.replace(/\//g, "");
    if (HideAddBtn.includes(Url)) {
      if (token && info?.role_id === 3) {
        setHideBtn(true);
      } else {
        setHideBtn(false);
      }
    } else {
      setHideBtn(false);
    }
  }, [pathname, info, token]);

  const RedirectPage = (url) => {
    history.push(url);
  };

  return (
    <header>
      <div className="container">
        <div className="second_header">
          <div className="inner_logo">
            <a href="javascript:void(0);" onClick={() => RedirectPage("/")}>
              <img src={Logo} alt="Logo" />
            </a>
          </div>
          <div className="head_link">

            {IsSeller && <a href="javascript:void(0);" onClick={() => RedirectPage("/account")} className={pathname === "/account" ? "active" : ""}>My Listing</a>}
            {/* <a href="javascript:void(0);" onClick={() => RedirectPage("/account")} className={pathname === "/account" ? "active" : ""}>My Listing</a> */}


            {IsSeller && <a href="javascript:void(0);" onClick={() => RedirectPage("/inquiries")} className={(pathname === "/inquiries" || pathname === "/viewInquiries") ? "active" : ""}>Inquiries</a>}

            {!IsSeller && <a href="javascript:void(0);" onClick={() => RedirectPage("/myOrders")} className={(pathname === "/myOrders" || pathname === "/orderDetails") ? "active" : ""}>My Current Booking</a>}

            <a href="javascript:void(0);" onClick={() => RedirectPage("/profile")} className={pathname === "/profile" ? "active" : ""}>Profile</a>

            {HideBtn && (
              <a
                href="javascript:void(0);"
                onClick={() => RedirectPage("/add-item")}
                className="seller_add_btn"
              >
                <i class="fas fa-plus-octagon"></i>New Listing
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
